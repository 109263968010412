import { Route } from 'react-router-dom';
import './App.css';
import Header from './Components/Header/Header';
import { Homepage } from './Components/Homepage/Homepage';
import Footer from './Components/Footer/Footer';


const openInstagram = () => {
  window.open('https://www.instagram.com/dazekart.in/', '_blank');
};
const openWhatsapp = () => {
  window.open('https://wa.me/message/AQUIYFJIETPIO1', '_blank');
};



function App() {
  return (
    <div>


      <Header />
      <Homepage openInstagram={openInstagram} openWhatsapp={openWhatsapp}  />
      <Footer />




    </div>
  );
}

export default App;
