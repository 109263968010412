import React from 'react'
import './Homepage.css'
import working from '../../Assets/Working.png'
import { BiLogoInstagramAlt } from 'react-icons/bi'
import { IoLogoWhatsapp } from 'react-icons/io'
import { FaFacebookSquare } from 'react-icons/fa'

export const Homepage = ({ openInstagram, openWhatsapp, openFacebook }) => {
    return (
        <div className="homepage_container">
            <div className="messageText">
                Comming Soon!
            </div>
            <div className="subMessageText">
                Our website is under Construction
            </div>
            <img src={working} alt="loading image" className="workingImage" />
            <div className="contactContainer">
                <span className="contactText">Follow us for more update</span>
                <div className="socialMediaContainer">
                    <BiLogoInstagramAlt size={60} onClick={openInstagram} />
                    <IoLogoWhatsapp size={60} onClick={openWhatsapp} />
                </div>
                
            </div>

        </div>
    )
}
